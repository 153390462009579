/*######################################################
	reset
#######################################################*/
html,
body{
    margin:0;
    padding:0;
}

body {
  background-color: #ffffff;
  color: #2a2a2a;
  height: 100%;
  min-height: 100vh;
  font-weight: 500;
}


html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container-fluid {
  max-width: 1140px;
}

#main-wrapper {
  min-height:  100vh;
  margin-bottom: -90px;
  padding-top: 110px;
  position: relative;
  z-index: 5;

  /* footer fixed */
  &:after {
    display: block;
    content: "";
    height: 90px; // this value equal to footer outer height
  }   

  @include media-breakpoint-down(sm) {
    padding-top: 86px;
    margin-bottom: -60px;

     &:after {
        height: 60px;
     }
  }

   @include media-breakpoint-down(xs) {
    padding-top: 71px;
    margin-bottom: -48px;

    &:after {
        height: 48px;
     }
  }
}
hr {
  border: 0;
  border-top: 1px solid #e7eaf3;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  box-shadow: none;
  outline: 0 none;
}