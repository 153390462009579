/*######################################################
	footer
#######################################################*/
footer#footer {
	background-color: #2b2f40;
	font-size: .875rem;
	padding-top: 4rem;
	text-align: center;
	color: darken($yellow, 10%);

	@include media-breakpoint-down(sm) {
		padding-top: 2rem;
	}



	.text-muted {
		a,
		a:active,
		a:hover {
			@include transition (all linear .2s);
			color: #6c757d;
		}
	}
}

.footer {
	&-logo {
		background-color: transparent;
		background-image: url('img/derry-kesuma-footer-logo.png');
		background-repeat:no-repeat;
		background-size:contain;
		background-position:center;
		display: block;
		height: 131px;
		max-width: 161px;
		margin: auto; 
		width: 100%;
		text-indent: -999em;
		overflow: hidden;
		outline: none;
	}

	&-copy {
		color: $white;

		[class*="icon-"],
		[class^="icon-"] {
			display: inline-block;
			margin-right: 5px;
		}

		a {
			color: $white;

			&:hover,
			&:active,
			&:focus {
				color: $white;
			}
		}
	}

	&-nav {
	 	color: $white;
		list-style: none;
		padding: 0;

	 	a {
	 		color: $white;
	 	}
	}

	&-block {
		padding-top: 1rem;
		padding-bottom: 1rem;

		+ .footer-block {
			padding-top: 20px;
			border-top: 1px solid #534741;
		}
	}

	&-title {
		font-family: $font-secondary;
		font-size: 1.125rem;
		font-weight: bold;
		margin-bottom: 1rem;

		@include media-breakpoint-down(sm) {
			margin-bottom: .6rem;
		}
	}

	&-col-nav,
	&-col-contact {
		@include media-breakpoint-down(sm) {
			padding-top: 20px;
		}
	}
}