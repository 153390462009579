.ps-servicescategory {
	@include media-breakpoint-up(lg) {
		position: relative;
		margin-top: -180px;
	}

	@include media-breakpoint-down(md) {
		padding-top: 20px;
	}

	> .container-fluid {
		padding-top: 0;
		padding-bottom: 0;
	}

	.card-group {
		background-color: #fff;

		@include media-breakpoint-down(md) {
			display: block;
		}
	}

	.card {
		@include border-radius(0);
		border: none;
		border-top: 15px solid $yellow;
		padding: 55px 20px 40px 20px;
		text-align: center;

		@include media-breakpoint-down(md) {
			padding: 1.5em 2em .5em 2em;
		}

		img {
			width: auto;
		}

		p {
			line-height: 1.8em;
		}

		&:nth-child(3n+2) {
			border-color: darken($yellow, 5%);
		}

		&:nth-child(3n+3) {
			border-color: darken($yellow, 10%);
		}
	}

	.card-image {
		padding-bottom: 1.5rem; 

		[class^="icon-"],
		[class*="icon-"] {
			font-size: 4rem;
			color: darken($yellow, 10%);
		}
	}

	.card-title {
		color: darken($yellow, 10%);
		font-family: $font-secondary;
	    font-weight: 600;
	    font-size: 1.5rem;
	    margin-bottom: 1.5rem;
	}

	.card-body {
		color: #2a2a2a;
		padding-bottom: 0;

		@include media-breakpoint-down(md) {
			padding: 0;
		}

		&-inner {
			@include media-breakpoint-up(lg) {
				@include transition(max-height 0.15s ease-out);
				max-height: 147px;
				overflow: hidden;
			}
		}

		p, 
		span,
		div {
			color: #2a2a2a;
		}
	}

	.card-footer {
		background-color: transparent;
		border: none;
	}

	.read-more {
		background-color: transparent;
		border: none;
		cursor: pointer;
	    font-size: .85em;
	    color: darken($yellow, 10%);
	    font-weight: bold;
	    letter-spacing: .1em;
		padding: 0 0 16px 0;
		position: relative;
		margin-top: 15px;

		@include media-breakpoint-down(md) {
			display: none;
		}

		&:focus,
		&:active {
			outline: none !important;
		}

		&::before {
			content : "Read more";
		}

		&::after {
			@extend .icon-chevron-down:before;
			font-family: "icomoon";
			display: inline-block;
			font-size: 10px;
			position: absolute;
			bottom: 0;
			left: 50%;
			margin-left: -7px;
		}
	}



	.card.is-expanded {
		.read-more {
			&::before {
				content: "Read less";
			}
			&::after {
				@include transform (rotate(-180deg));
			}
		}

		.card-body-inner {
			@include transition (max-height 2s ease-in);
			max-height: 3000px;
			
		}
	}
}