/*------------------------------------------
	font-face icomoon
------------------------------------------*/
@font-face {
    font-family: 'icomoon';
    src:    url('fonts/icomoon/icomoon.eot?knv62b');
    src:    url('fonts/icomoon/icomoon.eot?knv62b#iefix') format('embedded-opentype'),
        url('fonts/icomoon/icomoon.ttf?knv62b') format('truetype'),
        url('fonts/icomoon/icomoon.woff?knv62b') format('woff'),
        url('fonts/icomoon/icomoon.svg?knv62b#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-smartphone:before {
  content: "\e900";
}
.icon-polynates:before {
  content: "\e903";
}
.icon-polynates-inverse:before {
  content: "\e904";
}
.icon-play:before {
  content: "\ea1c";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-vimeo:before {
  content: "\eaa0";
}
.icon-behance:before {
  content: "\eaa8";
}
.icon-youtube:before {
  content: "\e920";
}
.icon-envelope:before {
  content: "\e901";
}
.icon-phone:before {
  content: "\e902";
}
.icon-linkedin:before {
  content: "\eaca";
}

