/*######################################################
  experts
#######################################################*/
.ps-experts {
	background-image: url('img/bg-experts.jpg');
	background-repeat: no-repeat;
	background-position: center center; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	.page-section-title,
	.page-section-subtitle {
		color: #fff !important;
	}

	.card {
		@include border-radius(0);
		margin: 1em;

		img {
			height: auto;
			width: 100%;
			max-width: 332px;
		}
	}

	.card-title {
		font-weight: bold;
		font-size: 1em;
		text-align: center;
	}
}