/*######################################################
  testimonials
#######################################################*/
.ps-testimonials {
		background-image: url('img/bg-testimonials.jpg'); 
		background-repeat: no-repeat;
		background-position: center center; 
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;

		.page-section-title {
			color: #fff;
		}
}

.testimonials {	
	&-item {
		padding-left: .9375rem;
		padding-right: .9375rem;
		opacity: 0;
		transition: opacity .3s liniear;

		.slick-initialized & {
			opacity: 1;
		}

		~ .testimonials-item {
			display: none;
		}
	}

	&-author {
		color: #fff;
		position: relative;

		h4 {
			font-weight: bold;
		}
	}

	&-avatar {
		width: 3.125rem;
		height: 3.125rem;
		margin-bottom: 1em;
	}

	&-body {
		color: $white;
		font-family: $font-secondary;
		font-size: 1.2rem;
		font-style: italic;
		padding: 2rem 3rem 3rem 3rem;
		line-height: 1.6rem;
		max-width: 850px;
		margin: auto;
	}

	.slick-dots li button {
		cursor: pointer;
		height: 15px;
		width: 15px;
		border-color: #fff;	
		background-color: transparent;	
	}

	.slick-dots li.slick-active button {
		background-color: #fff;	
	}
}
