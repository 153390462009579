/*######################################################
	content
#######################################################*/
main#main {
  .home.page-template & {
    padding-top: 0;
  }
  .page-template &,
  .category & {
    padding-top: 122px;

    @include media-breakpoint-between(md, md) {
      padding-top: 106px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 91px;
    }
  }
}


.link-more {
  @include transition(all linear .2s);
  display: inline-block;
  font-style: italic;
  font-size: .85em;
  font-weight: bold;

  &:active,
  &:hover,
  &:focus {
    color: darken($yellow, 10%);
    text-decoration: none;
  }

  &:after {
    @extend .icon-double-arrow-right:before;
    display: inline-block;
    font-family: "icomoon";
    position: relative;
    top: 2px;
    margin-left: 7px;
  }
}

.archives-list {
  max-width: 786px;
  margin: auto;

  li {
    border-color: $yellow;
    border-width: 1px;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 2em;
    line-height: 1.8em;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 1.7em;

    + li {
      padding-top: 1.7em;
    }
  }
}

.navigation, 
.pagination {
  .screen-reader-text {
    display: none;
  }

  padding-top: 20px;

  .nav-links {
    display: flex;
    margin: auto;
    @include list-unstyled();
    @include border-radius();

    > a,
    > span {
      font-weight: bold;
      position: relative;
      display: block;
      padding: .5rem .75rem;
      margin-left: -1px;
      line-height: 1.25;
      background-color: #fff;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }
}