/*######################################################
  portfolio
#######################################################*/
.portfolio {
	&-list {
		padding-top: 20px;
	}
	
	&-item {
		border: none;
  		transition: all 0.2s ease-in-out;

  		&:hover, &:focus {
  			@include media-breakpoint-up(sm) {
			  -webkit-transform: translateY(-3px);
			          transform: translateY(-3px);
			}
		}

		.portfolio-list & {
			margin-bottom: 1.4rem;
		}
	}

	&-navs {
		padding-top: 20px;
		padding-bottom: 20px;

		.btn {			
			outline: none;
			box-shadow: none;

			&:active,
			&:focus,
			&:hover {
				outline: none;
				box-shadow: none;
			}

			&:not(.btn-primary) {
				background-color: transparent;
    			border-color: #d5dae2;
				color: #8c98a4;
			}
		}
	}


	&-home {
		.portfolio-item {
			background-color: transparent;
			margin-bottom: 32px;
		}

		.card-body {
			background-color: #fff;
		}
	}
}