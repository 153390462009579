/*######################################################
  navbar
#######################################################*/
.navbar { 

  &-nav {

    li:first-child {
      @include media-breakpoint-down(md) {
        padding-top: 30px;
      }
    }

    a {
      color: $white;
      font-weight: 600;

      &:active,
      &:focus {
        color: $white;
      }

      &:hover {
        color: $yellow;
      }
    }

    .current-menu-item,
    .current_page_item {
      a {
        color: $yellow;
      }
    }
  }

  .menu-item,
  .nav-item {
    padding-left: 1em;
    padding-right: 1em;

    @include media-breakpoint-between(lg, lg) {
      padding-left: 0;
      padding-right: 0;
    }

    > a {
      @include transition(background-color ease .2s);
      display: inline-block;
      padding: .5rem;
      text-transform: uppercase;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &-brand {
    background-color: transparent;
    background-image: url('img/dk-logo.png');
    background-repeat:no-repeat;
    background-size:contain;
    background-position:center;
    display: inline-block;
    height: 86px;
    width: 100%;
    max-width: 93px;
    text-indent: -999em;
    overflow: hidden;
    outline: none;

    @include media-breakpoint-down(md) {
      height: 70px;
      max-width: 80px;
    }

    @include media-breakpoint-down(sm) {
      height: 55px;
      max-width: 60px;
    }
  }
}






/* toggler animation */  
$accent-color: hsl(0, 0, 50);
.navbar-toggler {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 35px;
  right: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background-color: transparent;
  border-radius: 50%;
  border: none;
  outline: none;

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }

  span {
    position: absolute;
    width: 30px;
    height: 3px;
    top: 50%;
    left: 50%;
    background: $white;
    border-radius: 2px;
    overflow: hidden;
    transition: all 0.3s linear;

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 100%;
      top: 0;
      right: 0;
      background: $accent-color;
      transition: all 0.3s linear;
    }

    &:nth-child(1) {
      animation: span-first-off 0.5s ease-in-out;
      animation-fill-mode: forwards;
    }
    &:nth-child(2) {
      animation: span-second-off 0.5s ease-in-out;
      animation-fill-mode: forwards;
    }
    &:nth-child(3){
      animation: span-third-off 0.5s ease-in-out;
      animation-fill-mode: forwards;
    }
  }
}
    
.navbar-toggler.on {
  &:hover span::before {
    width: 100%;
    transition: all 0.3s linear;
  }
  span {
    &:nth-child(1) {
      animation: span-first-on 0.5s ease-in-out;
      animation-fill-mode: forwards;
    }
    &:nth-child(2) {
      animation: span-second-on 0.5s ease-in-out;
      animation-fill-mode: forwards;
    }
    &:nth-child(3) {
      animation: span-third-on 0.5s ease-in-out;
      animation-fill-mode: forwards;
    }
  }
}

     
@keyframes span-first-on {
  0% {
    transform: translate(-50%, -300%);
  }
  30%{
    transform: translate(-50%, -50%);
  }
  100%{
    transform: translate(-50%, -50%) rotate(-45deg);
  }

}
@keyframes span-first-off {
  0%{
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  30%{
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100%{
    transform: translate(-50%, -300%);
  }
}
  
@keyframes span-second-on {
  0% {
    transform: translate(-50%, -50%);
  }
  25% {
    background: $accent-color;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-150%, -50%) scale(0);
  }
}

@keyframes span-second-off {
  0% {
    transform: translate(-150%, -50%) scale(0);
  }
  25%{
    background: $accent-color;
  }
  50%{
    transform: translate(-50%, -50%) scale(1);
  }
  100%{
    transform: translate(-50%, -50%);
  }
}
    
@keyframes span-third-on {
  0%{
    transform: translate(-50%, 200%);
  }
  30%{
    transform: translate(-50%, -50%);
  }
  100%{
    transform: translate(-50%, -50%) rotate(45deg);
  }
}


@keyframes span-third-off {
  0%{
    transform: translate(-50%, -50%) rotate(45deg);
  }
  30%{
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100%{
    transform: translate(-50%, 200%);
  }
}
