/*######################################################
	buttons
#######################################################*/
.btn {
	font-weight: 500;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.5);
}


/* Pill */
.btn-pill {
  border-radius: 6.1875rem;
}
