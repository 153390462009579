/*######################################################
	contact page
#######################################################*/
.contact {

	/* info */
	&-info {

		&-block {
			&:not(:last-child) {
				@include media-breakpoint-up(lg) {
					border-right: 1px solid #e7eaf3;
				}

				@include media-breakpoint-only(xs) {
					border-bottom:  1px solid #e7eaf3;
					padding-bottom: 20px;
				}
			}
		}

		&-icon {
			position: relative;
			padding: 20px;

			&-main {
				color: $green;
				font-size: 25px;
				position: absolute;
				left: calc(50% - 8px);
				top: calc(50% - 14px);
			}

			&-frame {
				font-size: 60px;
				color: #E6F6F2;
			}
		}

		&-title {
			padding-left: 10px;
			padding-right: 10px;
			line-height: 1.2em;
			margin: auto;
			max-width: 300px;
		}

		&-detail {
			color: #77838f;
			padding-top: 8px;
			padding-left: 10px;
			padding-right: 10px;
			line-height: 1.2em;
			margin: auto;
			max-width: 300px;
		}
	}


	/* location */
	&-location {

		@include media-breakpoint-down(sm) {
			padding-top: 30px;
			padding-bottom: 30px;
		}

		@include media-breakpoint-down(xs) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}

	&-map {
		min-height: 500px;
	}

	&-form {

		label {
			font-weight: bold;
			margin-bottom: 2px;

			> span {
				display: block;
				padding-top: 8px;
			}
		}

		.form-control {
			@include border-radius(0);
			border-color: rgba(0, 0, 0, 0.08);
			box-shadow: 0 1px 5px rgba(0, 0, 0, 0.04), 0 4px 7px rgba(0, 0, 0, 0.08);
		}

		.wysiwyg {
			line-height: 1.7em;
			padding-top: 30px;

			@include media-breakpoint-down(md) {
			}

			h5 {
				color: $yellow;
				font-weight: 600;
				margin-bottom: 20px;
			}

			[class*="icon-"],
			[class^="icon-"] {
				color: $yellow;
				display: inline-block;
				margin-right: 5px;
			}
		}
	}
}