/*######################################################
  banner subpage
#######################################################*/
.banner-subpage {
	background-color: rgba(1,10,25,.75);
	&-image {
		min-height: 300px;
		display: table;
		width: 100%;

		@include media-breakpoint-between(md, md) {
			min-height: 260px;
		}

		@include media-breakpoint-down(sm) {
			min-height: 200px;
		}

		background-repeat: no-repeat;
		background-position: center center; 
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		overflow: hidden;

		.is-full & {
			@include media-breakpoint-up(lg) {
				height: calc(100vh - 122px);
			}

			@include media-breakpoint-between(md,md) {
				height: 600px;
			}

			@include media-breakpoint-between(sm,sm) {
				height: 450px;
			}
		}
	}

	&-inner {
		display: table-cell;
		position: relative;
		vertical-align: middle;
		width: 100%;

		.is-has-wave & {
			padding-bottom: 50px;
		}

		.dzsparallaxer {
			position: absolute;
			left: 0;
			opacity: 0;
			height: 100%;
			top: 0;
			width: 100%;
			z-index: 0;

			&.dzsprx-readyall {
				opacity: 1;
			}
		}
	}

	&-title {
		color: $yellow;
		font-family: $font-secondary;
		font-size: 4.5rem;
		line-height: 1.1em;
		margin: 0;
		padding-bottom: 40px;
		//text-shadow: 1px 0 10px rgba(255, 255, 255, 0.3);			

		@include media-breakpoint-between(sm,sm) {
			font-size: 3.2rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 2.5rem;			
		}

		.is-title-large & {		
			@include media-breakpoint-up(lg) {
				font-size: 6rem;
			}
		}
	}

	.container-fluid {
		position: relative;
		z-index: 5;
	}
}