/*######################################################
  card
#######################################################*/
.card {
	&-body {
		p {
			color: #77838f;
			font-weight: 400;	
		}
	}

	&-text {
		font-size: 0.875rem;
	}
}