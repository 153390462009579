/*######################################################
	banner carousel
#######################################################*/
.banner-carousel {

	&-item {

		~ .banner-carousel-item {
			display: none;
		}
	}

	&-image {
		height: 100vh;
		min-height: 800px;
		background-repeat: no-repeat;
		background-position: center center; 
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		position: relative;

		@include media-breakpoint-down(md) {
			height: auto;
			min-height: 100%;
		}

		> .container-fluid {
			height: 100%;
		}
	}

	&-row {
		color: #fff;
		position: relative;
		z-index: 5;
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			display: table;
			height: 100%;
			margin: auto;
		}
	}

	&-col {
		@include media-breakpoint-up(lg) {
			display: table-cell;
			padding-top: 160px;
			padding-bottom: 168px;
			vertical-align: bottom;
			height: 100%;
		}

		&-text {
			@include media-breakpoint-up(lg) {
				padding-bottom: 20rem;
				width: 60%;
			}
			@include media-breakpoint-between(md,lg) {
				padding-top: 140px;
				padding-left: 50px;
			}

			@include media-breakpoint-down(sm) {
				padding-top: 120px;
			}
		}

		&-photo {
			@include media-breakpoint-up(lg) {
				text-align: right;
			}

			@include media-breakpoint-down(md) {
				padding: 30px 30px 0 30px;
				img {
					margin: auto;
				}
			}
		}
	}

	&-photo {
		display: block;
		overflow: hidden;

		@include media-breakpoint-between(sm, md) {
			margin: auto;
			max-height: 400px;
			max-width: 400px;
			text-align: center;

			img {
				height: auto;
				width: 100%;
				max-width: 100%;
			}
		}
		
		@include media-breakpoint-down(sm) {
			margin: auto;
			max-height: 300px;
			max-width: 300px;
			text-align: center;

			img {
				height: auto;
				width: 100%;
				max-width: 100%;
			}
		}
	}

	&-description {
		.slick-active & {
			@extend .fadeInUp;
			@extend .animated;
		}
	}

	&-title {
		font-family: $font-secondary;
		font-size: 4rem;
		font-weight: normal;
		color: $yellow;
		margin-bottom: 1.5rem;

		@include media-breakpoint-between(md,lg) {
			font-size: 3.5rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 24px;
		}
	}

	&-info {
		font-family: $font-secondary;
		font-size: 1.6rem;
		font-style: italic;
		line-height: 1.6em;

		@include media-breakpoint-between(md,lg) {
			font-size: 1.4rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 16px;

		}
	}
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}