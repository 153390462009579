.ps-aboutus {
	position: relative;

	> .container-fluid {
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 30px;
		padding-right: 30px;
	}

	&-image {
		position: absolute;
		height: calc(100% - 100px);
		top: 50px;
		left: 0;
		width: 100%;
		z-index: 0;

		background-attachment: fixed;
		background-repeat: no-repeat;
		background-position: center center; 
		background-size: cover;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;


		&:before {
			background-color: rgba(1,10,25,.75);
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			position: absolute;
		}
	}

	.page-section-title {
		color: #fff;
	}

	.about {
		position: relative;
		z-index: 2;

		&-col {
			@include media-breakpoint-up(lg) {
				padding: 4rem 5rem;
			}

			@include media-breakpoint-between(md, md) {
				padding: 2rem 3rem;
			}

			@include media-breakpoint-down(sm) {
				padding: 2rem;
			}

			&-image {
				background-color: $white;
				background-repeat: no-repeat;
				background-position: center center; 
				background-size: cover;
				-webkit-background-size: cover;
				-moz-background-size: cover;
				-o-background-size: cover;

				@include media-breakpoint-up(lg) {
					order: 12;
					-webkit-order:12;
				}

				@include media-breakpoint-down(md) {
					min-height: 250px;
				}
			}

			&-text {
				  background-image: linear-gradient(150deg, #b08005 0%, #976e04 100%);
				background-repeat: repeat-x;
				color: $white;
				min-height: 100px;

				.wysiwyg {
					color: $white;
					line-height: 2em;
				}
			}

		}
	}
}