/*######################################################
	section
#######################################################*/
.page-section {
	position: relative;	

	&-title {
		color: $yellow;
		font-family: $font-secondary;
		font-size: 3.4rem;
		font-weight: 600;
		margin-bottom: 2.4rem;
		letter-spacing: .2rem;
		line-height: 3.4rem;

		@include media-breakpoint-between(md,md) {
			font-size: 2.8rem;
			margin-bottom: 1.5rem;
			letter-spacing: .1rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 2.6rem;
			margin-bottom: 1rem;
			letter-spacing: 0;
		}
	}

	&-subtitle {
		font-size: 1.1em;
		font-weight: normal;
		position: relative;
		margin-top: -30px;
		margin-bottom: 50px;
		letter-spacing: 1.2px;

		@include media-breakpoint-down(md) {
			margin-top: -10px;
		}
	}

	> .container-fluid,
	> .container {
		padding-top: 50px;
		padding-bottom: 50px;

		@include media-breakpoint-down(sm) {
			padding-top: 30px;
			padding-bottom: 30px;
		}

		@include media-breakpoint-down(xs) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}
}

.section-divider {
	@include media-breakpoint-up(xl) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    
    @include media-breakpoint-between(md, lg) {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    @include media-breakpoint-between(sm, sm) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
}