/*######################################################
  header
#######################################################*/
header#header {
	@include transition(background-color ease .2s);
	background-color: rgba(0,0,0,.5);
	padding-top: 10px;
	padding-bottom: 10px;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 10;

	.home.page-template & {
		background-color: rgba(0,0,0,.5);
	}

	&.is-bs-scroll,
	.page-template &,
  	.category & {
		background-color: $black;
	}

	&.is-bs-scroll {
		.home.page-template & {
			background-color: $black;
		}
	}

	@include media-breakpoint-down(md) {
		background-color: $black;
	}
		
}