.ps-caseservices {
	
	@include media-breakpoint-up(lg) {
		border-bottom: 60px solid #f2f3f8;
	}

	.card-deck {
		@include media-breakpoint-down(md) {
			display: block;
		}
	}
	.card {
		border: none;
		max-width: 500px;

		@include media-breakpoint-down(md) {
			margin: auto;
		}
	}

	.card-title {
		color: $yellow;
		font-family: $font-secondary;
		font-size: 1.4rem;
		font-weight: 600;
		letter-spacing: 2px;
	}

	.card-body {	
		padding-left: 0;
		padding-right: 0;

		@include media-breakpoint-up(lg) {
			border-top: 2px solid $yellow;
		}
	}

	.card-footer {
		background-color: transparent;
		border-top: 2px solid $yellow;
		padding-left: 0;
		padding-right: 0;

		@include media-breakpoint-down(md) {
			text-align: right;
		}
	}

	.card-list {
		margin: 0;
		padding: 0 0 0 15px;
		line-height: 1.2rem;


		li {
			font-size: .95rem;
			line-height: 1.5em;
			
			+ li {
				padding-top: 10px;
			}
		}
	}
}